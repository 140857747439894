<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Message
        v-if="task?.maxTime"
        :closable="false"
        :severity="
          task?.maxTime < currentCommandTimeInMinutes ? 'warn' : 'success'
        "
      >
        Рекомендованное время {{ minutesToHours(task?.maxTime) }}
      </Message>
      <Dropdown
        v-if="permissions['add_optional_engineer']"
        :filter="true"
        v-model="selectedEngineer"
        :options="engineers"
        placeholder="Нажмите, чтобы выбрать инженера для добавления в командную работу"
        optionLabel="engineerLabel"
        emptyFilterMessage="Совпадений не найдено"
      >
        <template #option="slotProps">
          <div class="employee-item">
            <div>
              {{ slotProps.option.engineerLabel }}
            </div>
          </div>
        </template>
      </Dropdown>
    </div>
    <div class="p-col-12">
      <DataTable
        :value="taskEngineers"
        :paginator="true"
        class="p-datatable-customers p-datatable-striped"
        :rows="10"
        dataKey="id"
        :rowHover="true"
        :autoLayout="true"
        :loading="isLoading"
      >
        <template #empty>Список сотрудников пуст</template>
        <template #loading>Загрузка...</template>
        <Column field="firstname" header="Имя" :sortable="true">
          <template #body="slotProps">{{
            slotProps.data.user.firstname
          }}</template>
        </Column>
        <Column field="lastname" header="Фамилия" :sortable="true">
          <template #body="slotProps">{{
            slotProps.data.user.lastname
          }}</template>
        </Column>
        <Column field="phone" header="Телефон" :sortable="true">
          <template #body="slotProps">{{ slotProps.data.user.phone }}</template>
        </Column>
        <Column
          field="coefficient"
          header="Отработанное время"
          :sortable="true"
        >
          <template #body="slotProps">
            <div class="editMode">
              <div v-if="editId !== slotProps.data.id" style="width: 100%">
                {{ minutesToHours(slotProps.data.coefficient) }}
              </div>
              <Dropdown
                :options="hoursOptions"
                v-model="currentHours"
                v-if="editId === slotProps.data.id"
              />
              <Dropdown
                :options="minutesOptions"
                v-model="currentMinutes"
                v-if="editId === slotProps.data.id"
              />
              <Button
                  v-if="
                  !editId &&
                  (permissions['add_optional_engineer'] ||
                    task?.engineer?.id === myId)
                "
                  :disabled="task?.engineer?.id === myId &&
                 ['WAIT_OPERATOR_CONFIRM', 'WAIT_CLIENT_CONFIRM', 'DONE'].includes(task?.status)"
                  class="p-button-warning"
                  icon="pi pi-pencil"
                  @click="
                  turnOnEditMode(slotProps.data.id, slotProps.data.coefficient)
                "
              />
              <Button
                icon="pi pi-check"
                v-if="editId === slotProps.data.id"
                @click="openChangeCoefficientWithMaxTimeDialog(slotProps.data)"
              />
            </div>
          </template>
        </Column>
        <Column>
          <template
            #body="slotProps"
            v-if="
              permissions['add_optional_engineer'] &&
              (task?.engineer?.id === myId ||
                role === ROLE.OPERATOR ||
                role === ROLE.MAIN_OPERATOR)
            "
          >
            <Button
              icon="pi pi-times"
              class="p-button-danger"
              @click="confirmDeleting(slotProps.data.id)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <div class="p-col-12">
      <div class="p-field">Отработанное командой время (часы): {{ time }}</div>

      <div class="p-field">
        Информация о реализации: {{ task?.realizationDescription }}
      </div>

      <div class="p-field">
        <label>Расстояние (километры)</label>
        <InputNumber
          id="distance"
          v-model="distance.val"
          mode="decimal"
          class="p-mb-2"
          :useGrouping="false"
          :class="!distance.isValid ? 'p-invalid' : ''"
          :disabled="!isChangeDistanceEnabled(task, role, myId)"
        />
        <Button
          v-if="!isLoading && isChangeDistanceEnabled(task, role, myId)"
          label="Сохранить"
          @click="submitForm('distance', distance.val ?? 0)"
        ></Button>
      </div>

      <div class="p-field">
        <label>Гос. номер и регион (формат А123АА 22)</label>
        <InputText
          id="auto"
          v-model="auto.val"
          :class="!auto.isValid ? 'p-invalid' : ''"
          class="p-mb-2"
          :disabled="!isChangeDistanceEnabled(task, role, myId)"
        />
        <Button
          v-if="!isLoading && isChangeDistanceEnabled(task, role, myId)"
          label="Сохранить"
          @click="submitForm('auto', auto.val)"
        ></Button>
      </div>
    </div>
    <ConfirmDialog group="commandWorkDialog"></ConfirmDialog>
    <ConfirmDialog group="changeCoefficientWithMaxTimeDialog"></ConfirmDialog>
  </div>
</template>

<script>
import { environment } from "@/config";
import { ROLE } from "@/models/roles";

export default {
  name: "TaskCommandWork",
  data() {
    return {
      ROLE,
      currentHours: null,
      currentMinutes: null,
      selectedEngineer: null,
      placeholder: "Выберите работников",
      isLoading: false,
      engineers: null,
      taskEngineers: null,
      editId: null,
      formIsValid: true,
      time: null,
      currentCommandTimeInMinutes: null,
      distance: { val: null, isValid: true },
      auto: { val: null, isValid: true },
    };
  },
  props: ["task", "taskEngineersFromParent"],
  async mounted() {
    this.taskEngineers = this.taskEngineersFromParent;
    await this.loadTaskEngineers();
    if (this?.task?.time) {
      this.time = this.task.time;
      this.currentCommandTimeInMinutes = this.time;

      this.time = this.minutesToHours(this.time);
    } else {
      this.time = `0 ч. 0 мин.`;
    }
    if (this?.task?.distance) {
      this.distance.val = this.task.distance;
    }
    this.auto.val = this?.task?.auto;
  },
  watch: {
    selectedEngineer: function (newValue) {
      if (newValue) {
        this.setNewTaskEngineer(newValue);
      }
    },
  },
  computed: {
    role() {
      return this.$store.getters.getRole;
    },
    taskEngineersUrl() {
      return `${environment.apiUrl}/tasks/${this.task.id}/engineers`;
    },
    permissions() {
      return this.$store.getters.getPermissions;
    },
    myId() {
      return this.$store.getters.getId;
    },
    hoursOptions() {
      return Array.from(Array(201).keys()).map((num) => {
        return `${num} ч.`;
      });
    },
    minutesOptions() {
      return Array.from([0, 10, 20, 30, 40, 50]).map((num) => {
        return `${num} мин.`;
      });
    },
  },
  methods: {
    minutesToHours(time) {
      return `${Math.floor(time / 60)} ч. ${time % 60} мин.`;
    },
    openChangeCoefficientWithMaxTimeDialog(engineer) {
      if (this.task.maxTime) {
        let workTimeWithoutCurrentEngineer = null;
        this.taskEngineers.map((currentEngineer) => {
          if (currentEngineer.id !== engineer.id)
            workTimeWithoutCurrentEngineer += currentEngineer.coefficient;
        });

        if (
          parseInt(this.currentHours) * 60 +
            parseInt(this.currentMinutes) +
            workTimeWithoutCurrentEngineer >
          (this.task.maxTime ?? 0)
        ) {
          this.$confirm.require({
            message:
              `Рекомендованное время на эту заявку ${this.task.maxTime} минут. ` +
              `Изменив время работы на текущее, Вы его превысите. Изменить время работы?`,
            group: "changeCoefficientWithMaxTimeDialog",
            header: "Изменение времени работы",
            acceptLabel: "Да",
            rejectLabel: "Нет",
            accept: () => {
              this.changeCoefficient(engineer);
            },
          });
        } else {
          this.changeCoefficient(engineer);
        }
      } else {
        this.changeCoefficient(engineer);
      }
    },
    isChangeDistanceEnabled(task, role, myId) {
      return (
        (task.status === "WORK_ENGINEER" && task.engineer.id === myId) ||
        role === ROLE.OPERATOR ||
        role === ROLE.HEAD_OF_SERVICE ||
        role === ROLE.CHIEF_ENGINEER
      );
    },
    confirmDeleting(id) {
      this.$confirm.require({
        message: "Вы действительно хотите удалить инженера из списка?",
        header: "Confirmation",
        group: "commandWorkDialog",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Да",
        rejectLabel: "Нет",
        accept: () => {
          this.deleteEngineerFromTeam(id);
        },
      });
    },
    validateForm(formName) {
      this.formIsValid = true;
      this.auto.isValid = true;
      let rusAutoRegexp = /^[а-я]\d{3}[а-я]{2} \d{2,3}$/gimu;
      switch (formName) {
        //Какая-то валидация все равно нужна, поэтому оставлю switch
        case "auto": {
          if (!this.auto.val || !rusAutoRegexp.test(this.auto.val)) {
            this.auto.isValid = false;
            this.formIsValid = false;
          }
          break;
        }
      }
    },
    async submitForm(formName, formVal) {
      this.validateForm(formName);

      if (!this.formIsValid) return;

      this.isLoading = true;

      try {
        let tmp_task = this.task;
        tmp_task[formName] = formVal;
        let response = await fetch(
          `${environment.apiUrl}/tasks/${this.task.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify(tmp_task),
          }
        );

        if (response.status === 400) {
          throw new Error();
        }

        this.$toast.add({
          severity: "success",
          summary: "Сохранено",
          detail: `${
            formName === "distance"
              ? "Расстояние изменено"
              : "Гос. номер изменен"
          }`,
          life: 6000,
        });
      } catch (error) {
        console.log(error);
        this.$toast.add({
          severity: "error",
          summary: "Ошибка",
          detail: `Не удалось сохранить введенные данные`,
          life: 6000,
        });
      }
      this.isLoading = false;
    },
    turnOnEditMode(editId, currValue) {
      this.editId = editId;
      this.currentHours = `${Math.floor(currValue / 60)} ч.`;
      this.currentMinutes = `${currValue % 60} мин.`;
    },
    async changeCoefficient(engineer) {
      this.isLoading = true;
      try {
        engineer.coefficient =
          parseInt(this.currentHours) * 60 + parseInt(this.currentMinutes);
        const response = await fetch(`${this.taskEngineersUrl}/${engineer.id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
          method: "PUT",
          body: JSON.stringify(engineer),
        });

        if (response.status !== 200) {
          const responseData = await response.json();
          throw new Error(
              responseData.message || "Ошибка смены времени"
          );
        }

        this.currentCommandTimeInMinutes = 0;
        await this.loadTaskEngineers();
        for (const engineer of this.taskEngineers) {
          this.currentCommandTimeInMinutes += engineer.coefficient;
        }

        this.time = this.minutesToHours(this.currentCommandTimeInMinutes);
        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Отработанные часы изменены`,
          life: 6000,
        });
        await this.loadTaskEngineers();
      } catch (err) {
        console.log(err);
        this.$toast.add({
          severity: "error",
          summary: "Не удалось обновить отработанные часы",
          detail: err,
          life: 6000,
        });
      }
      this.editId = null;
      this.isLoading = false;
    },
    async loadAllEngineers() {
      if (!this.task?.id) return;
      this.isLoading = true;
      try {
        if (this.taskEngineers === undefined) {
          this.taskEngineers = [];
        }
        this.engineers = this.$store.getters["myprofiles/engineers"];
        this.engineers = this.engineers.filter((e) => {
          return !this.taskEngineers
            .map((t) => t.user)
            .some((elem) => elem.id === e.id);
        });
        this.engineers = this.engineers.map((e) => {
          return {
            engineerInfo: { ...e },
            engineerLabel: `${e.firstname} ${e.lastname} ${e.phone}`,
          };
        });
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
    },
    async deleteEngineerFromTeam(id) {
      this.isLoading = true;
      try {
        const response = await fetch(`${this.taskEngineersUrl}/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
          method: "DELETE",
        });

        const responseData = await response.json();

        if (response.status === 400) {
          throw new Error(responseData.message);
        }
        this.taskEngineers = responseData;

        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Инженер удален`,
          life: 6000,
        });
        await this.loadTaskEngineers();
      } catch (err) {
        console.log(err);
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при удалении инженера",
          detail: err,
          life: 6000,
        });
      }
      this.isLoading = false;
    },
    async loadTaskEngineers() {
      this.isLoading = true;
      await this.loadAllEngineers();
      this.isLoading = false;
    },
    async setNewTaskEngineer(newEngineer) {
      this.isLoading = true;
      try {
        const response = await fetch(this.taskEngineersUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(newEngineer.engineerInfo),
        });

        const responseData = await response.json();

        if (response.status === 400) {
          throw new Error(responseData.message);
        }

        this.taskEngineers = responseData;

        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Инженер добавлен`,
          life: 6000,
        });
        await this.loadTaskEngineers();
      } catch (error) {
        console.log(error);
        this.$toast.add({
          severity: "error",
          summary: "Не удалось добавить инженера",
          detail: error,
          life: 6000,
        });
      }

      this.isLoading = false;
      this.selectedEngineer = null;
    },
  },
};
</script>

<style scoped>
.editMode {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.p-message {
  margin: 0 0 1rem 0;
}
</style>
